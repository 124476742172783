<template>
    <div class="clearfix"></div>
    <!-- BEGIN CONTAINER -->
    <div class="page-container">
        <!-- BEGIN CONTENT -->
        <div class="page-content-wrapper">
            <div class="page-content" style="font-size:12pt;">
                <div class="portlet box blue">
                    <div class="portlet-title">
                        <div class="caption">
                            <i class="fa fa-question-circle"></i> Privacy Policy
                        </div>
                    </div><br><br>
                    <div class="portlet-body">
                        <div class="" style="">
                            <div class="text-center">
                                <b class="font-lg">Privacy Policy</b>
                            </div>
                            <div class="">
                                TMS 2 app as a Free app. This SERVICE is provided by at no cost and is
                                intended for use as is.

                                This page is used to inform visitors regarding our policies with the collection, use, and
                                disclosure of Personal Information if anyone decided to use our Service.

                                If you choose to use our Service, then you agree to the collection and use of information in
                                relation to this policy. The Personal Information that we collect is used for providing and
                                improving the Service. We will not use or share your information with anyone except as
                                described in this Privacy Policy.

                                The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
                                which is accessible at eNothi System 2 unless otherwise defined in this Privacy Policy.
                            </div>

                            <div class="text-center">
                                <b class="font-lg">Information Collection and Use</b>
                            </div>
                            <div class="">
                                For a better experience, while using our Service, we may require you to provide us with
                                certain personally identifiable information. The information that we request will be
                                retained by us and used as described in this privacy policy.

                                The app does use third party services that may collect information used to identify you.

                                Link to privacy policy of third party service providers used by the app

                                Google Play Services
                                Firebase Analytics
                                Log Data

                                We want to inform you that whenever you use our Service, in a case of an error in the app we
                                collect data and information (through third party products) on your phone called Log Data.
                                This Log Data may include information such as your device Internet Protocol (“IP”) address,
                                device name, operating system version, the configuration of the app when utilizing our
                                Service, the time and date of your use of the Service, and other statistics.
                            </div>

                            <div class="text-center">
                                <b class="font-lg">Cookies</b>
                            </div>
                            <div class="">
                                Cookies are files with a small amount of data that are commonly used as anonymous unique
                                identifiers. These are sent to your browser from the websites that you visit and are stored
                                on your device's internal memory.

                                This Service does not use these “cookies” explicitly. However, the app may use third party
                                code and libraries that use “cookies” to collect information and improve their services. You
                                have the option to either accept or refuse these cookies and know when a cookie is being
                                sent to your device. If you choose to refuse our cookies, you may not be able to use some
                                portions of this Service.
                            </div>


                            <div class="text-center">
                                <b class="font-lg">Service Providers</b>
                            </div>
                            <div class="">
                                We may employ third-party companies and individuals due to the following reasons:

                                To facilitate our Service;
                                To provide the Service on our behalf;
                                To perform Service-related services; or
                                To assist us in analyzing how our Service is used.
                                We want to inform users of this Service that these third parties have access to your
                                Personal Information. The reason is to perform the tasks assigned to them on our behalf.
                                However, they are obligated not to disclose or use the information for any other purpose.
                            </div>

                            <div class="text-center">
                                <b class="font-lg">Security</b>
                            </div>
                            <div class="">
                                We value your trust in providing us your Personal Information, thus we are striving to use
                                commercially acceptable means of protecting it. But remember that no method of transmission
                                over the internet, or method of electronic storage is 100% secure and reliable, and we
                                cannot guarantee its absolute security.
                            </div>


                            <div class="text-center">
                                <b class="font-lg">Links to Other Sites</b>
                            </div>
                            <div class="">
                                This Service may contain links to other sites. If you click on a third-party link, you will
                                be directed to that site. Note that these external sites are not operated by us. Therefore,
                                we strongly advise you to review the Privacy Policy of these websites. We have no control
                                over and assume no responsibility for the content, privacy policies, or practices of any
                                third-party sites or services.
                            </div>


                            <div class="text-center">
                                <b class="font-lg">Children’s Privacy</b>
                            </div>
                            <div class="">
                                These Services do not address anyone under the age of 13. We do not knowingly collect
                                personally identifiable information from children under 13. In the case we discover that a
                                child under 13 has provided us with personal information, we immediately delete this from
                                our servers. If you are a parent or guardian and you are aware that your child has provided
                                us with personal information, please contact us so that we will be able to do necessary
                                actions.
                            </div>

                            <div class="text-center">
                                <b class="font-lg">Changes to This Privacy Policy</b>
                            </div>
                            <div class="">
                                We may update our Privacy Policy from time to time. Thus, you are advised to review this
                                page periodically for any changes. We will notify you of any changes by posting the new
                                Privacy Policy on this page. These changes are effective immediately after they are posted
                                on this page.
                            </div>

                            <div class="text-center">
                                <b class="font-lg">Contact Us</b>
                            </div>
                            <div class="">
                                Contact Us
                                If you have any questions or suggestions about our Privacy Policy, do not hesitate to
                                contact us.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END CONTENT -->
    </div>
</template>

<style>
.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: transparent;
}

.page-header.navbar .page-logo .logo-default {
    margin-left: 0px;
}

body {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.portlet.box.blue>.portlet-title {
    background-color: #23bc1e;
}

.portlet.box>.portlet-title {
    border-bottom: 0;
    padding: 10px;
    margin-bottom: 0;
    color: #fff;
}

.page-content {
    background: transparent;
}

.page-container {
    padding: 44px 35px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle>img {
    margin-top: -20px;
    margin-left: 5px;
    height: 60px;
    display: inline-block;
}
</style>
